import React, { useState, useEffect } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { API_URL, USER_DETAILS_URL } from '../../constants';
import axios from 'axios';
import { loginActions } from '../../store/login-slice';



const API = axios.create({
  baseURL: API_URL,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Authorization': `Bearer ${localStorage.getItem('access_token')}`,
  }
});

const Navbar = () => {
  const dispatch = useDispatch();
  const isLogin = useSelector(state => state.login.isLogin);

  useEffect(() => {

    getUserDetails();


  }, [isLogin]);


  const getUserDetails = async () => {
    try {

      const response = await API.get(USER_DETAILS_URL);
      if(response.status === 200) {
        dispatch(loginActions.setUser(response.data));
      }
    
    } catch (e) {
      console.log(e)
    }
  }



  const toggleOffcanvas = () => {
    document.querySelector('.sidebar-offcanvas').classList.toggle('active');
  }
  const toggleRightSidebar = () => {
    document.querySelector('.right-sidebar').classList.toggle('open');
  }

  return (
    <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
      <div className="text-center navbar-brand-wrapper">
        <Link className="navbar-brand brand-logo-mini" to="/"><img src={require('../../assets/images/logo-mini.svg')} alt="logo" /></Link>
      </div>
      <div className="navbar-menu-wrapper d-flex align-items-stretch">
        <button className="navbar-toggler navbar-toggler align-self-center" type="button" onClick={() => document.body.classList.toggle('sidebar-icon-only')}>
          <span className="mdi mdi-chevron-double-left"></span>
        </button>

        <ul className="navbar-nav navbar-nav-right">

          <li className="nav-item language-dropdown">
            <Dropdown alignRight>
              <Dropdown.Toggle className=" btn btn-link nav-link">
                <Trans>English</Trans>
              </Dropdown.Toggle>

              <Dropdown.Menu className="navbar-dropdown">
                <Dropdown.Item href="!#" onClick={evt => evt.preventDefault()}>
                  <i className="flag-icon flag-icon-fr mr-3"></i>
                  <Trans>French</Trans>
                </Dropdown.Item>
                <Dropdown.Item href="!#" onClick={evt => evt.preventDefault()}>
                  <i className="flag-icon flag-icon-cn mr-3"></i>
                  <Trans>Chinese</Trans>
                </Dropdown.Item>
                <Dropdown.Item href="!#" onClick={evt => evt.preventDefault()}>
                  <i className="flag-icon flag-icon-de mr-3"></i>
                  <Trans>German</Trans>
                </Dropdown.Item>
                <Dropdown.Item href="!#" onClick={evt => evt.preventDefault()}>
                  <i className="flag-icon flag-icon-es mr-3"></i>
                  <Trans>Spanish</Trans>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </li>
          <li className="nav-item nav-logout d-none d-lg-block">

            <Link className="btn btn-link nav-link" to="/logout"><i className="mdi mdi-power"></i></Link>
          </li>

        </ul>

        <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" onClick={toggleOffcanvas}>
          <span className="mdi mdi-menu"></span>
        </button>
      </div>
    </nav>
  );
}

export default Navbar;
