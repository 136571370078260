import { createSlice } from "@reduxjs/toolkit";

const requestSlice = createSlice({
    name: 'request',
    initialState: { requests: []},
    reducers: {
        setRequest(state, action) {
            state.requests = action.payload;
        },

    },
});

export const requestActions = requestSlice.actions;

export default requestSlice;
