import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { Collapse } from 'react-bootstrap';
import { Trans } from 'react-i18next';
import Username from './Username';
class Sidebar extends Component {

  state = {};

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({ [menuState]: false });
    } else if (Object.keys(this.state).length === 0) {
      this.setState({ [menuState]: true });
    } else {
      Object.keys(this.state).forEach(i => {
        this.setState({ [i]: false });
      });
      this.setState({ [menuState]: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    document.querySelector('#sidebar').classList.remove('active');
    Object.keys(this.state).forEach(i => {
      this.setState({ [i]: false });
    });



  }

  render() {
    return (
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <ul className="nav">
          <li className="nav-item nav-profile border-bottom">
            <a href="!#" className="nav-link flex-column" onClick={evt => evt.preventDefault()}>
              <div className="nav-profile-image">
                <Link to="/dashboard" className="nav-link sidebar-logo flex-column align-items-baseline">
                  <img src={require("../../assets/images/logo.png")} alt="logo" />

                </Link>
              
              </div>
              <div className="nav-profile-text ml-0 mb-3 text-center">
                <span className="font-weight-semibold mb-1 mt-2 font-14"><Trans><Username /></Trans></span>
                <span className="text-secondary font-16"><Trans></Trans></span>
              </div>
            </a>
          </li>


          <li className={this.isPathActive('/dashboard') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to="/dashboard">
              <i className="mdi mdi-poll-box menu-icon"></i>
              <span className="menu-title"><Trans>
                <Trans>Dashboard</Trans>
              </Trans></span>
            </Link>
          </li>
          <li className={this.isPathActive('/request') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to="/request">
              <i className="mdi mdi-phone menu-icon"></i>
              <span className="menu-title">Call Request</span>
            </Link>
          </li>

          {/* <li className={this.isPathActive('/logs') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to="/logs">
              <i className="mdi mdi-format-list-bulleted menu-icon"></i>
              <span className="menu-title">Activity Log</span>
            </Link>
          </li> */}

          <li className={this.isPathActive('/history') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to="/history">
              <i className="mdi mdi-phone-log menu-icon"></i>
              <span className="menu-title">Call History</span>
            </Link>
          </li>
          <li className={this.isPathActive('/logout') ? 'nav-item active' : 'nav-item'}>
            <Link className="nav-link" to="/logout">
              <i className="mdi mdi-power menu-icon"></i>
              <span className="menu-title">Logout</span>
            </Link>
          </li>

        </ul>
      </nav>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  componentDidMount() {
    this.onRouteChanged();
    // add class 'hover-open' to sidebar navitem while hover in sidebar-icon-only menu
    const body = document.querySelector('body');
    document.querySelectorAll('.sidebar .nav-item').forEach((el) => {

      el.addEventListener('mouseover', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.add('hover-open');
        }
      });
      el.addEventListener('mouseout', function () {
        if (body.classList.contains('sidebar-icon-only')) {
          el.classList.remove('hover-open');
        }
      });
    });
  }

}

export default withRouter(Sidebar);