import React, { Component, Suspense, lazy, useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Spinner from '../app/shared/Spinner';
import { useSelector } from 'react-redux'
import { loginActions } from './../store/login-slice';

const Dashboard = lazy(() => import('./pages/Dashboard'));
const History = lazy(() => import('./pages/History'));
const Logs = lazy(() => import('./pages/Logs'));
const Logout = lazy(() => import('./pages/Logout'));
const Request = lazy(() => import('./pages/Request'));
const Call = lazy(() => import('./pages/Call'));

const Login = lazy(() => import('./user-pages/Login'));


const AppRoutes = () => {
  const isLogin = useSelector((state) => state.login.isLogin);
  const dispatch = useDispatch();

  useEffect(() => {

    if (localStorage.getItem('access_token') === null) {
      dispatch(loginActions.setLogout())
    } else {
      dispatch(loginActions.setLogin())
    }
  }, [])

  return (
    <Suspense fallback={<Spinner />}>
      <Switch>
        <Route exact path="/dashboard">
          {isLogin ? <Dashboard /> : <Redirect to="/login" />}
        </Route>

        <Route exact path="/history">
          {isLogin ? <History /> : <Redirect to="/login" />}
        </Route>
        <Route exact path="/logs">
          {isLogin ? <Logs /> : <Redirect to="/login" />}
        </Route>
        <Route exact path="/logout">
          {isLogin ? <Logout /> : <Redirect to="/login" />}
        </Route>
        <Route exact path="/request">
          {isLogin ? <Request /> : <Redirect to="/login" />}
        </Route>

        <Route path="/login">
          {isLogin ? <Redirect to="/dashboard" /> : <Login />}
        </Route>

        <Route exact path="/call/:requestId/:callId" render={(props) => (
          <>
            {isLogin ? <Call requestId={props.match.params.requestId} callId={props.match.params.callId} /> : <Redirect to="/login" />}
          </>
        )

        } />


        <Redirect to="/dashboard" />
      </Switch>
    </Suspense>
  );

}

export default AppRoutes;